import MaterialTable from "@material-table/core";
import AddIcon from "@mui/icons-material/Add";
import { Box, Fab, Fade, Paper, Tooltip } from "@mui/material";
import { useStoreActions, useStoreState } from "easy-peasy";
import React from "react";
import { materialTableLocalization } from "../../config";
import api from "../../services/api";
import DialogoFornecedor from "./DialogoFornecedor";
import FunctionsIcon from "@mui/icons-material/Functions";
import CheckIcon from "@mui/icons-material/Check";
import Styles from "./Fornecedores.module.css";
import DialogoOrcamentosFornecedor from "./DialogoOrcamentosFornecedor";

const Fornecedores = () => {
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  let user = useStoreState((state) => state.auth.user);
  const tableRef = React.createRef();
  const downloadLinkRef = React.useRef(null);

  const [columns, setColumns] = React.useState([]);

  const getOrcamentosCount = async (id) => {
    const orcamentos = await api.http.get(`/fornecedores/orcamentos/${id}/tecnologias`);

    return orcamentos.data.totais[0];
  };

  const loadFornecedores = async (query) => {
    startLoading();
    try {
      let getFornecedores = (
        await api.http.get("/fornecedores", {
          params: {
            page: query.page + 1,
            pageSize: query.pageSize,
            search: query.search,
            orderBy: query.orderBy ? query.orderBy.field : undefined,
            orderDirection: query.orderDirection
              ? query.orderDirection
              : undefined,
          },
        })
      ).data;

      const fornecedorescomorcamento = getFornecedores.data;

      const fornecedoresWithOrcamentos = await Promise.all(
        fornecedorescomorcamento.map(async (fornecedor) => {
          const orcamentos = await getOrcamentosCount(fornecedor.id);
          return {
            ...fornecedor,
            orcamentos,
          };
        })
      );

      stopLoading();
      return {
        data: fornecedoresWithOrcamentos,
        page: getFornecedores.meta.current_page - 1,
        totalCount: getFornecedores.meta.total,
      };
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      return {
        data: [],
        page: 0,
        totalCount: 0,
      };
    }
  };

  React.useEffect(() => {
    setColumns([
      {
        field: "nome",
        align: "left",
        title: "Nome",
      },
      {
        field: "responsavel",
        align: "left",
        title: "Responsável",
      },
      {
        field: "email",
        align: "left",
        title: "Email",
      },
      {
        field: "cnpj",
        align: "left",
        title: "CNPJ",
        cellStyle: { width: "300px", minWidth: "200px" },
        headerStyle: { minWidth: "200px" },
      },
      {
        field: "uf",
        align: "center",
        title: "U.F.",
      },
      {
        field: "habilitado",
        align: "center",
        title: "Habilitado",
        type: "boolean",
      },
      {
        field: "orcamentos",
        align: "center",
        title: "Orçamentos",
        disableClick: true,
        sorting: false,
        render: (rowData) => (
          <div
            className={Styles.orcamentosColumn}
            onClick={() => {
              onOrcamentosClick(rowData);
            }}
          >
            <div className={Styles.orcamentosText}>
              <Tooltip
                title="Total de Solicitações Recebidas"
                placement="top"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                classes={{ tooltip: Styles.tooltip }}
              >
                <FunctionsIcon fontSize="small" />
              </Tooltip>
              {rowData.orcamentos[0].total}
            </div>

            <div className={Styles.orcamentosText}>
              <Tooltip
                title="Orçamentos Respondidos"
                placement="top"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                classes={{ tooltip: Styles.tooltip }}
              >
                <CheckIcon color="success" fontSize="small" />
              </Tooltip>
              {rowData.orcamentos[0].respondidos}
            </div>
          </div>
        ),
      },
    ]);
  }, []);

  const [openDlgFornecedor, setOpenDlgFornecedor] = React.useState(false);
  const [fornecedor, setFornecedor] = React.useState(null);
  const [reloadTable, setReloadTable] = React.useState(0);
  const [openDlgOrcamentosFornecedor, setOpenDlgOrcamentosFornecedor] =
    React.useState(false);

  const onCloseDlgFornecedor = (reload = false) => {
    setFornecedor(null);
    setOpenDlgFornecedor(false);
    if (reload) setReloadTable(reloadTable + 1);
  };

  const onCloseDlgOrcamentosFornecedor = (reload = false) => {
    setFornecedor(null);
    setOpenDlgOrcamentosFornecedor(false);
    if (reload) setReloadTable(reloadTable + 1);
  };

  const onRowClick = (event, rowData) => {
    setFornecedor(rowData);
    setOpenDlgFornecedor(true);
  };

  const onOrcamentosClick = (rowData) => {
    setFornecedor(rowData);
    setOpenDlgOrcamentosFornecedor(true);
  };

  React.useEffect(() => {
    if (reloadTable > 0) tableRef.current && tableRef.current.onQueryChange();
  }, [reloadTable]);

  const importarFornecedores = async () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept =
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";

    input.click();

    input.onchange = async () => {
      startLoading();
      try {
        const file = input.files[0];
        const formData = new FormData();
        formData.append("file", file);

        const response = await api.http.post(
          "/fornecedores/importar",
          formData
        );

        stopLoading({
          message: response.data.message,
          severity: "success",
        });
        setReloadTable(reloadTable + 1);
      } catch (error) {
        stopLoading({
          message: error.response.data.message,
          severity: "error",
        });
      }
    };
  };

  return (
    <Box sx={{ width: "100%" }}>
      <a
        ref={downloadLinkRef}
        style={{ display: "none" }}
        download="Modelo_Importação_Fornecedores.xlsx"
        href="/Modelo_Importação_Fornecedores.xlsx"
      ></a>
      <DialogoFornecedor
        open={openDlgFornecedor}
        onClose={onCloseDlgFornecedor}
        fornecedor={fornecedor}
      />
      <DialogoOrcamentosFornecedor
        open={openDlgOrcamentosFornecedor}
        onClose={onCloseDlgOrcamentosFornecedor}
        fornecedor={fornecedor}
      />
      <Paper
        variant="outlined"
        className="center-box"
        sx={{ width: "100%", my: 3 }}
      >
        <MaterialTable
          columns={columns}
          data={loadFornecedores}
          title="Fornecedores"
          onRowClick={onRowClick}
          localization={materialTableLocalization}
          tableRef={tableRef}
          options={
            (user.roleId === "superAdmin" || user.roleId === "gestorSENAI") && {
              exportMenu: [
                {
                  label: "IMPORTAR FORNECEDORES",
                  exportFunc: () => importarFornecedores(),
                },
              ],
            }
          }
        />
        <Fab
          color="primary"
          aria-label="novo fornecedor"
          onClick={() => {
            setOpenDlgFornecedor(true);
          }}
        >
          <AddIcon />
        </Fab>
      </Paper>
    </Box>
  );
};

export default Fornecedores;