import { yupResolver } from "@hookform/resolvers/yup";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // FormControl,
  FormControlLabel,
  Checkbox as MUICheckbox,
  // Tabs,
  // Tab,
  Box, Paper,
  Typography,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Grid, IconButton, Collapse,
  TextField,
  FormControl,
} from "@mui/material";

import {
  useStoreActions,
  useStoreState
} from "easy-peasy";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Autocomplete, FileListField, Select, DateField, FileField }
  from "../../components";
import api from "../../services/api";
import { tiposCargas, tituloTabelaAddOrcamentoPorCarga, dialogoTomadaPreco } from "../../static";
import { formatCurrency, parseValue } from "../../services/utils";

import {
  KeyboardArrowDown, KeyboardArrowUp
  //  , RequestQuoteOutlined 
} from "@mui/icons-material";

//import ViewTomadaPrecoFaseOneHeader from "../../components/TomadaPreco/ViewTomadaPrecoFaseOneHeader";
//import ViewTomadaPrecoFaseOneBody from "../../components/TomadaPreco/ViewTomadaPrecoFaseOneBody";

// import PropTypes from 'prop-types';
// import { ConstructionOutlined } from "@mui/icons-material";

//import { ViewTableBodyIluminacao } from "./BodyFaseOne/ViewTableBodyIluminacao";

import '../StepPreDiagnosticoRelatorios/ModalRelResumo.css';

import IlumicacaoFaseTwoTabelaItens from "./BodyItensFaseTwo/IlumicacaoFaseTwoTabelaItens"
import CondicionadoresArFaseTwoTabelaItens from "./BodyItensFaseTwo/CondicionadoresArFaseTwoTabelaItens";
import MotoresEletricosFaseTwoTabelaItens from "./BodyItensFaseTwo/MotoresEletricosFaseTwoTabelaItens";
import BombasFaseTwoTabelaItens from "./BodyItensFaseTwo/BombasFaseTwoTabelaItens";
import CompressoresFaseTwoTabelaItens from "./BodyItensFaseTwo/CompressoresFaseTwoTabelaItens";
import VentiladoresFaseTwoTabelaItens from "./BodyItensFaseTwo/VentiladoresFaseTwoTabelaItens";
import InversoresFequenciaFaseTwoTabelaItens from "./BodyItensFaseTwo/InversoresFrequenciaFaseTwoTabelaItens";
import RefrigeracaoFaseTwoTabelaItens from "./BodyItensFaseTwo/RefrigeracaoFaseTwoTabelaItens";
import SgeFaseTwoTabelaItens from "./BodyItensFaseTwo/SgeFaseTwoTabelaItens";
import CogeracaoFaseTwoTabelaItens from "./BodyItensFaseTwo/CogeracaoFaseTwoTabelaItens";
import IsolamentoTermicoFaseTwoTabelaItens from "./BodyItensFaseTwo/IsolamentoTermicoFaseTwoTabelaItens";
import CaldeirasFaseTwoTabelaItens from "./BodyItensFaseTwo/CaldeirasFaseTwoTabelaItens";
import QueimadoresFaseTwoTabelaItens from "./BodyItensFaseTwo/QueimadoresFaseTwoTabelaItens";
import FornosFaseTwoTabelaItens from "./BodyItensFaseTwo/FornosFaseTwoTabelaItens";
import AquecedoresFaseTwoTabelaItens from "./BodyItensFaseTwo/AquecedoresFaseTwoTabelaItens";
import ColetoresSolaresFaseTwoTabelaItens from "./BodyItensFaseTwo/ColetoresSolaresFaseTwoTabelaItens";
import ReservatoriosTermicosFaseTwoTabelaItens from "./BodyItensFaseTwo/ReservatoriosTermicosFaseTwoTabelaItens";
import TrocadoresCalorFaseTwoTabelaItens from "./BodyItensFaseTwo/TrocadoresCalorFaseTwoTabelaItens";
import BombasCalorFaseTwoTabelaItens from "./BodyItensFaseTwo/BombasCalorFaseTwoTabelaItens";

import { DialogoVisualizador } from '../../components';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
// import userEvent from "@testing-library/user-event";
// import { set } from "date-fns";
// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DialogoAcompanhaOrcamentosFaseTwo = ({ open, onClose, orcamento, itens, isFornecedor = false }) => {
  // let user = useStoreState((state) => state.auth.user);
  let loading = useStoreState((state) => state.nav.loading);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  // let currProcesso = useStoreState((state) => state.processo.current);

  // const [dados, setDados] = React.useState();
  // const [somaTotal, setSomaTotal] = React.useState(0);
  const [save, setSave] = React.useState(false);
  const [send, setSend] = React.useState(false);
  const [metas, setMetas] = React.useState([]);
  const [atividades, setAtividades] = React.useState({});
  const [openRows, setOpenRows] = React.useState({});
  const [openDlgVis, setOpenDlgVis] = React.useState(false);
  const [fileVisualizador, setFileVisualizador] = React.useState();
  //const [isCalculoFinanceiro, setIsCalculoFinanceiro] = React.useState(false);
  const [isSelected, setIsSelected] = React.useState(false);
  const [rows, setRows] = React.useState(itens?.data || []);

  const defaultValues = {
    documentoId: orcamento?.documentoId || null,
  };

  const schema = yup
    .object({
      documentoId: yup.number()
      .positive()
      .nullable()
      .label("Orçamento"),
    });

  const useFormRef = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });


  const {
    watch,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { isDirty, isSubmitting },
  } = useFormRef;

  const handleRowChange = (index,field, newValue) => {
    setRows((prevRows) => {
      console.log("prev rows", prevRows);
      const updatedRows = [...prevRows];
      updatedRows[index] = { ...updatedRows[index], [field]: newValue };
      return updatedRows;
    });
  };
  const [isDisabled, setIsDisabled] = React.useState(true);

  // Obtem os cabeçalhos conforme o tipoCarga
  const selectedHeaders = tituloTabelaAddOrcamentoPorCarga[orcamento.tipoCarga] || {};

  // Array de colunas com os títulos não vazios
  const columns = Object.values(selectedHeaders).filter(header => header !== "");

  // Adiciona sempre a coluna 15 (Valor unitário R$)
  columns.push("Valor unitário R$");
  columns.push("Valor total R$");

  const getTipoCarga = (cod) => {
    const nomecarga = tiposCargas.find((e) => e.id === cod);
    return nomecarga ? nomecarga.label : "Desconhecido";
  }

  const formatDateToPtBr = (dateString) => {
    // Verifica se a string contém informações de timezone
    dateString = dateString.substring(0, 10);
    const date = new Date(dateString.includes('T') ? dateString : `${dateString}T00:00:00`);

    // Formata a data para o formato 'dd/mm/yyyy'
    return date.toLocaleDateString('pt-BR', {
      timeZone: 'America/Sao_Paulo', // Garante o fuso horário correto
    });
  };

  const close = () => {
    // reset(defaultValues);
    onClose(true);
  };

  const preencherInfo = (name, chave) => {
    // Localiza o objeto correto com base no 'name'
    const itemSelecionado = dialogoTomadaPreco.find(item => item.name === name);

    if (!itemSelecionado || !itemSelecionado[chave]) return null; // Verifica se existe a chave

    // Retorna o conteúdo para ser renderizado
    return itemSelecionado[chave].map(info => (
      <Grid container key={info.ordem} spacing={2}>
        <Grid item xs={1} className="item-response-color">
          <Typography variant="subtitle2" gutterBottom className='margemtexto'>
            {info.ordem}
          </Typography>
        </Grid>
        <Grid item xs={11} className="item-response">
          <Typography variant="subtitle2" gutterBottom className='margemtexto'>
            {info.detalhe}
          </Typography>
        </Grid>
      </Grid>
    ));
  };

  const totais = React.useMemo(() => {
    // // Garantir que rows seja um array válido antes de usar reduce
    const validRows = Array.isArray(itens?.data) ? itens?.data : [];
    return validRows.reduce(
      (acc, row) => ({
        coluna14: acc.coluna14 + parseValue(row.coluna14),
        coluna15: acc.coluna15 + parseValue(row.coluna15),
        coluna16: acc.coluna16 + parseValue(row.coluna16),
        colunaB13: acc.colunaB13 + parseValue(row.colunaB13),
        colunaB14: acc.colunaB14 + parseValue(row.colunaB14),
        colunaB15: acc.colunaB15 + parseValue(row.colunaB15),
      }),
      { coluna14: 0, coluna15: 0, coluna16: 0, colunaB13: 0, colunaB14: 0, colunaB15: 0 }
    );
  }, [itens?.data]);

  React.useEffect(async () => {
    try {
      const getmetas = (await api.http.get("/orcamentos/processo/meta/" + orcamento?.id)).data;
      setMetas(getmetas);
      // await this.isExistsFinanceCalc();
    } catch (error) {
      console.error("Erro ao recuperar as metas:", error);
    }
  }, [orcamento?.id]);

  // const isExistsFinanceCalc = async () => {
  //   try {
  //     const calculoFinanceiro = (await api.http.get(`orcamentos/processo/orcamento/calculo/financeiro/${orcamento?.processoId}/${orcamento?.tipoCarga}`)).data;
  //     if (calculoFinanceiro) {
  //       //setIsCalculoFinanceiro(true);
  //       return true
  //     }
  //     return false
  //   } catch (error) {
  //     console.error("Erro ao recuperar existencia orçamento selecionado para calculo financeiro:", error);
  //   }

  // };


  const isExistsFinanceCalc = async () => {
    try {
      const calculoFinanceiro = (
        await api.http.get(`orcamentos/processo/orcamento/calculo/financeiro/${orcamento?.processoId}/${orcamento?.tipoCarga}`)
      ).data;
      return !!calculoFinanceiro;
    } catch (error) {
      console.error("Erro ao verificar cálculo financeiro existente:", error);
      return true; // Desabilita o botão em caso de erro
    }
  };

  React.useEffect(() => {
    const checkCalculoFinanceiro = async () => {
      const exists = await isExistsFinanceCalc();
      setIsDisabled(exists);
    };

    checkCalculoFinanceiro();
  }, [orcamento?.processoId, orcamento?.tipoCarga]);

  React.useEffect(() => {
    setIsSelected(orcamento.selecionado);
  }, [orcamento])

  const fetchAtividades = async (metaId) => {
    if (atividades[metaId]) return; // Já buscou as atividades para essa meta
    try {
      const response = await api.http.get(`/orcamentos/processo/atividade/${metaId}`);
      setAtividades((prevAtividades) => ({
        ...prevAtividades,
        [metaId]: response.data,
      }));
    } catch (error) {
      console.error("Erro ao recuperar atividades:", error);
    }
  };

  const handleRowClick = (metaId) => {
    setOpenRows((prevOpenRows) => ({
      ...prevOpenRows,
      [metaId]: !prevOpenRows[metaId],
    }));
    fetchAtividades(metaId); // Busca atividades quando a linha é expandida
  };

  async function handleSave(enviar){
    startLoading();
    orcamento.documentoId = watch("documentoId") || null;
    try {
      await api.http.put("/fornecedores/orcamentos/processo/tomada/alterarorcamento/" + orcamento?.id
        , {
          orcamento,
          tomadas: rows,
          respostaFase1At: enviar, 
      });
      if(enviar){
        close();
      }
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  };

  const onOpenDlgVis = (param1, param2, param3) => {
    setFileVisualizador(param1);
    setOpenDlgVis(true);
  };
  const onCloseDlgVis = () => {
    setOpenDlgVis(false);
  };

  const containsOnlyNumbersAndSymbols = (str) => {
    const regex = /^[\d\W]+$/;
    return regex.test(str);
  }

  const somarItensFinanciaveis = () => {
    if (!orcamento) {
      console.warn("O objeto orcamento não está definido.");
      return 0;
    }

    const { servicosConsultoria, treinamentos, equipamentos, servicosInstalacao, softwares } = orcamento;
    const valores = [servicosConsultoria, treinamentos, equipamentos, servicosInstalacao, softwares];

    // Função auxiliar para converter string de número em float, considerando vírgula como separador decimal
    const parseValue = (value) => {
      if (typeof value === "string") {
        value = value.replace(".", "").replace(",", ".");
      }
      const parsed = parseFloat(value);
      return isNaN(parsed) ? 0 : parsed;
    };

    // Soma dos valores válidos
    return valores.reduce((acc, curr) => acc + parseValue(curr), 0);
  };

  const onAddCalculoFinanceiro = async () => {
    try {
      startLoading();

      ////confirmar se não existe outro selecionado
      const isExstsSelecionado = isExistsFinanceCalc();

      ////consfirmar se a data já expirou
      const isExpirado = isFutureDate(orcamento?.finishedAt);

      if (isExstsSelecionado) {
        stopLoading({
          message: "Já existe um orçamento sendo utilizado para calculo financeiro",
          severity: 'error'
        });
      }

      if (!isExpirado) {
        stopLoading({
          message: "Prazo de validade do orçamento expirado.",
          severity: 'error'
        });
      }


      await api.http.put(`/orcamentos/processo/orcamento/calculo/financeiro/${orcamento?.id}`);
      // setIsCalculoFinanceiro(true);
      stopLoading();
    } catch (error) {
      console.error("Erro ao selecionar o orçamento para calculo financeiro:", error);
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    }
  };

  const isFutureDate = (timestamp) => {
    // Converte o timestamp para uma data
    const dateToCompare = new Date(timestamp);

    // Pega a data e hora atual
    const currentDate = new Date();

    // Retorna true se o timestamp for maior que a data atual
    console.log("data final: ", dateToCompare > currentDate)
    return dateToCompare > currentDate;
  };

  const handleCheckboxChange = async (event) => {
    const selecionado = event.target.checked;
    setIsSelected(selecionado);

    try {
      await api.http.put(`/orcamentos/processo/selecionado/${orcamento?.id}`, {
        selecionado: selecionado,
      });
      console.log("Orçamento atualizado com sucesso:", { id: orcamento?.id, selecionado });
    } catch (error) {
      console.error("Erro ao selecionar o orçamento:", error);
    }
  };

  console.log("orcamentos: ", orcamento);

  return (
    <Dialog open={open} onClose={() => onClose()} fullScreen>
      <DialogTitle>Visualização tomada de preços 2ª Fase</DialogTitle>
      <DialogContent dividers>
        <DialogoVisualizador file={fileVisualizador} open={openDlgVis} onClose={onCloseDlgVis} />
        <Box className="title">
          <Typography align="center" variant="subtitle1" className="titleFontColor">
            PROGRAMA POTENCIALIZEE - {getTipoCarga(orcamento?.tipoCarga)}
          </Typography>
        </Box>
        {/* <Box className="title" sx={{ background: "#62bb46", width: "90%", marginTop: "40px" }}> */}
        <Grid container spacing={0} sx={{ marginTop: "10px" }}>
          <Grid item xs={6} className="item-response-color">
            <Typography align="left" variant="subtitle1" className="titleFontColor">
              PEDIDO DE ORÇAMENTO: {orcamento?.id}
            </Typography>
          </Grid>
          <Grid item xs={6} className="item-response-color">
            <Typography align="left" variant="subtitle1" className="titleFontColor">

            </Typography>
          </Grid>
        </Grid>

        {/* </Box> */}
        <Box className="title">
          <Typography align="left" variant="subtitle1" className="titleFontColor">
            DADOS DE IDENTIFICAÇÃO DO SOLICITANTE:
          </Typography>
        </Box>
        <Grid container spacing={0}>
          <Grid item xs={4} className="item-response-color">
            <Typography variant="subtitle2" gutterBottom className="titleFontColor">Cidade: </Typography>
          </Grid>
          <Grid item xs={8} className="item-response">
            <Typography variant="subtitle2" gutterBottom className='margemtexto'>{orcamento?.fornecedor?.cidade}</Typography>
          </Grid>
          <Grid item xs={4} className="item-response-color">
            <Typography variant="subtitle2" gutterBottom className="titleFontColor">UF: </Typography>
          </Grid>
          <Grid item xs={8} className="item-response">
            <Typography variant="subtitle2" gutterBottom className='margemtexto'>{orcamento?.fornecedor?.uf}</Typography>
          </Grid>
          <Grid item xs={4} className="item-response-color">
            <Typography variant="subtitle2" gutterBottom className="titleFontColor">Validade da proposta </Typography>
          </Grid>
          <Grid item xs={8} className="item-response">
            <Typography variant="subtitle2" gutterBottom className='margemtexto'> {orcamento?.validadeProposta} </Typography>
          </Grid>
        </Grid>

        <Box className='title'>
          <Typography variant="h6" gutterBottom className='titleFontColor'>
            INFORMAÇÕES ADICIONAIS - {getTipoCarga(orcamento.tipoCarga).toUpperCase()} QUE PREFERENCIALMENTE APRESENTEM:
          </Typography>
        </Box>
        <Grid container spacing={0} className="gridTitleContainer">
          {preencherInfo(orcamento?.tipoCarga, "infodefault")}
        </Grid>

        <Box className='title'>
          <Typography variant="h6" gutterBottom className='titleFontColor'>
            INFORMAÇÕES ADICIONAIS QUE DEVEM ESTAR CONTIDAS NA PROPOSTA::
          </Typography>
        </Box>
        <Grid container spacing={0} className="gridTitleContainer">
          {preencherInfo(orcamento?.tipoCarga, "infoproposta")}
        </Grid>

        <Box className='title'>
          <Typography variant="h6" gutterBottom className='titleFontColor'>
            INFORMAÇÕES SOBRE O FORNECEDOR:
          </Typography>
        </Box>

        <Grid container spacing={0}>
          <Grid item xs={4} className="item-response-color">
            <Typography variant="subtitle2" gutterBottom className="titleFontColor">Fornecedor:</Typography>
          </Grid>
          <Grid item xs={8} className="item-response">
            <Typography variant="subtitle2" gutterBottom className='margemtexto'> {orcamento?.fornecedor?.nome}</Typography>
          </Grid>

          <Grid item xs={4} className="item-response-color">
            <Typography variant="subtitle2" gutterBottom className="titleFontColor">Prazo Máximo para retorno:</Typography>
          </Grid>
          <Grid item xs={8} className="item-response">
            <Typography variant="subtitle2" gutterBottom className='margemtexto'> {orcamento.finishedAt ? formatDateToPtBr(orcamento.finishedAt) : ""}</Typography>
          </Grid>

          <Grid item xs={4} className="item-response-color">
            <Typography variant="subtitle2" gutterBottom className="titleFontColor">Data Retorno:</Typography>
          </Grid>
          <Grid item xs={8} className="item-response">
            <Typography variant="subtitle2" gutterBottom className='margemtexto'> {orcamento.respostafase1At ? formatDateToPtBr(orcamento.respostafase1At) : ""}</Typography>
          </Grid>
        </Grid>

        {orcamento?.documentoId && (
          <Grid container spacing={0} sx={{ marginTop: "10px" }}>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <MUICheckbox
                    checked={isSelected}
                    onChange={handleCheckboxChange}
                    color="primary"
                  />
                }
                label="Orçamento selecionado"
              />
            </Grid>
            <Grid item xs={4} >
              {/* {!isCalculoFinanceiro() && isFutureDate(orcamento?.finishedAt) && ( */}


              <Button variant="outlined" startIcon={<RequestQuoteOutlinedIcon />}
                color="success"
                disabled={isDisabled}
                onClick={() => onAddCalculoFinanceiro()}
              >
                Usar para cálculo finaneiro
              </Button>

            </Grid>
            <Grid item xs={4} >
              <Typography align="left" variant="subtitle1" className="titleFontColor">
                <Button variant="outlined" startIcon={<ArticleOutlinedIcon />}
                  color="secondary"
                  onClick={() => onOpenDlgVis(orcamento?.documento, orcamento?.id, 'Valor3')}
                >
                  Visualizar orçamento
                </Button>
              </Typography>
            </Grid>
          </Grid>
        )}

        <Box className='title'>
          <Typography variant="h6" gutterBottom className='titleFontColor'>
            INFORMAÇÕES SOBRE {getTipoCarga(orcamento.tipoCarga).toUpperCase()} A SEREM ORÇADAS:
          </Typography>
        </Box>

        {orcamento?.tipoCarga === "iluminacao" && (
          <IlumicacaoFaseTwoTabelaItens rows={itens?.data} isFornecedor={isFornecedor} onRowChange={handleRowChange}  />
        )}

        {orcamento?.tipoCarga === "condicionadores_de_ar" && (
          <CondicionadoresArFaseTwoTabelaItens rows={itens?.data} isFornecedor={isFornecedor} onRowChange={handleRowChange}  />
        )}

        {orcamento?.tipoCarga === "motores_eletricos" && (
          <MotoresEletricosFaseTwoTabelaItens rows={itens?.data} isFornecedor={isFornecedor} onRowChange={handleRowChange}  />
        )}

        {orcamento?.tipoCarga === "bombas" && (
          <BombasFaseTwoTabelaItens rows={itens?.data} isFornecedor={isFornecedor} onRowChange={handleRowChange}  />
        )}

        {orcamento?.tipoCarga === "compressores" && (
          <CompressoresFaseTwoTabelaItens rows={itens?.data} isFornecedor={isFornecedor} onRowChange={handleRowChange}  />
        )}

        {orcamento?.tipoCarga === "ventiladores" && (
          <VentiladoresFaseTwoTabelaItens rows={itens?.data} isFornecedor={isFornecedor} onRowChange={handleRowChange}  />
        )}

        {orcamento?.tipoCarga === "inversores_de_frequencia" && (
          <InversoresFequenciaFaseTwoTabelaItens rows={itens?.data} isFornecedor={isFornecedor} onRowChange={handleRowChange}  />
        )}

        {orcamento?.tipoCarga === "refrigeracao" && (
          <RefrigeracaoFaseTwoTabelaItens rows={itens?.data} isFornecedor={isFornecedor} onRowChange={handleRowChange}  />
        )}

        {orcamento?.tipoCarga === "sistema_de_gerenciamento_de_energia" && (
          <SgeFaseTwoTabelaItens rows={itens?.data} isFornecedor={isFornecedor} onRowChange={handleRowChange}  />
        )}

        {orcamento?.tipoCarga === "cogeracao" && (
          <CogeracaoFaseTwoTabelaItens rows={itens?.data} isFornecedor={isFornecedor} onRowChange={handleRowChange}  />
        )}

        {orcamento?.tipoCarga === "isolamento_termico" && (
          <IsolamentoTermicoFaseTwoTabelaItens rows={itens?.data} isFornecedor={isFornecedor} onRowChange={handleRowChange}  />
        )}

        {orcamento?.tipoCarga === "caldeiras" && (
          <CaldeirasFaseTwoTabelaItens rows={itens?.data} isFornecedor={isFornecedor} onRowChange={handleRowChange}  />
        )}

        {orcamento?.tipoCarga === "queimadores" && (
          <QueimadoresFaseTwoTabelaItens rows={itens?.data} isFornecedor={isFornecedor} onRowChange={handleRowChange}  />
        )}

        {orcamento?.tipoCarga === "fornos" && (
          <FornosFaseTwoTabelaItens rows={itens?.data} isFornecedor={isFornecedor} onRowChange={handleRowChange}  />
        )}

        {orcamento?.tipoCarga === "aquecedores" && (
          <AquecedoresFaseTwoTabelaItens rows={itens?.data} isFornecedor={isFornecedor} onRowChange={handleRowChange}  />
        )}

        {orcamento?.tipoCarga === "coletores_solares" && (
          <ColetoresSolaresFaseTwoTabelaItens rows={itens?.data} isFornecedor={isFornecedor} onRowChange={handleRowChange}  />
        )}

        {orcamento?.tipoCarga === "reservatorios_termicos" && (
          <ReservatoriosTermicosFaseTwoTabelaItens rows={itens?.data} isFornecedor={isFornecedor} onRowChange={handleRowChange}  />
        )}

        {orcamento?.tipoCarga === "trocadores_de_calor" && (
          <TrocadoresCalorFaseTwoTabelaItens rows={itens?.data} isFornecedor={isFornecedor} onRowChange={handleRowChange}  />
        )}

        {orcamento?.tipoCarga === "bombas_de_calor" && (
          <BombasCalorFaseTwoTabelaItens rows={itens?.data} isFornecedor={isFornecedor} onRowChange={handleRowChange}  />
        )}

        <Grid container spacing={1} sx={{ marginTop: "15px" }}>
          <Grid item xs={3} className="item-response-color">
            <Typography variant="subtitle2" gutterBottom className="titleFontColor">PRAZO PARA ENVIO DO EQUIPAMENTO: </Typography>
          </Grid>
          <Grid item xs={6} className="item-response">
            <Typography variant="subtitle2" gutterBottom className='margemtexto'>{orcamento?.prazoEntrega} DIAS</Typography>
          </Grid>
          <Grid item xs={3} className="item-response">
            <Typography variant="subtitle2" gutterBottom className='gridItemTitleResponseright'>{formatCurrency(totais.colunaB15)}</Typography>
          </Grid>
          <Grid item xs={3} className="item-response-color">
            <Typography variant="subtitle2" gutterBottom className="titleFontColor">CONDIÇÕES DE PAGAMENTO: </Typography>
          </Grid>
          <Grid item xs={9} className="item-response">
            <Typography variant="subtitle2" gutterBottom className='margemtexto'>{orcamento?.observacoes}</Typography>
          </Grid>
        </Grid>

        <Box className='title'>
          <Typography variant="h6" gutterBottom className='titleFontColor'>
            DETALHAMENTO TÉCNICO DA SOLICITAÇÃO:
          </Typography>
        </Box>

        <Grid container spacing={0} sx={{ width: 600, marginTop: "10px" }}>
          <Grid item xs={12} className="item-response-color">
            <Typography variant="subtitle2" gutterBottom className="titleFontColor">Quadro de usos e fontes</Typography>
          </Grid>
          <Grid item xs={6} className="item-response-color">
            <Typography variant="subtitle2" gutterBottom className="titleFontColor">Itens financiáveis </Typography>
          </Grid>
          <Grid item xs={6} className="item-response-color">
            <Typography variant="subtitle2" gutterBottom className="titleFontColor">Valor R$</Typography>
          </Grid>
          <Grid item xs={6} className="item-response">
            <Typography variant="subtitle2" gutterBottom className='margemtexto'>Equipamentos (nacionais e importados) </Typography>
          </Grid>
          <Grid item xs={6} className="item-response">
            {!isFornecedor ? (
              <Typography variant="subtitle2" gutterBottom className='margemtexto'>{!containsOnlyNumbersAndSymbols(orcamento?.equipamentos) ? orcamento?.equipamentos : formatCurrency(orcamento?.equipamentos)}</Typography>) : (
              <TextField
                id="equipamentos"
                name="equipamentos"
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                defaultValue={orcamento?.equipamentos}
                onChange={(e) => orcamento.equipamentos = e.target.value}
              />
            )}
          </Grid>

          <Grid item xs={6} className="item-response">
            <Typography variant="subtitle2" gutterBottom className='margemtexto'>Softwares </Typography>
          </Grid>
          <Grid item xs={6} className="item-response">
            {!isFornecedor ? (
            <Typography variant="subtitle2" gutterBottom className='margemtexto'>{!containsOnlyNumbersAndSymbols(orcamento?.softwares) ? orcamento?.softwares : formatCurrency(orcamento?.softwares)}</Typography> ) : (
              <TextField
                id="softwares"
                name="softwares"
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                defaultValue={orcamento?.softwares}
                onChange={(e) => orcamento.softwares = e.target.value}
              />
            )}
          </Grid>

          <Grid item xs={6} className="item-response">
            <Typography variant="subtitle2" gutterBottom className='margemtexto'>Matérias-primas e material de consumo </Typography>
          </Grid>
          <Grid item xs={6} className="item-response">
            {!isFornecedor ? (
            <Typography variant="subtitle2" gutterBottom className='margemtexto'>{!containsOnlyNumbersAndSymbols(orcamento?.materiaPrima) ? orcamento?.materiaPrima : formatCurrency(orcamento?.materiaPrima)}</Typography> ) : (
              <TextField
                id="materiaPrima"
                name="materiaPrima"
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                defaultValue={orcamento?.materiaPrima}
                onChange={(e) => orcamento.materiaPrima = e.target.value}
              />
            )}
          </Grid>

          <Grid item xs={6} className="item-response">
            <Typography variant="subtitle2" gutterBottom className='margemtexto'>Treinamentos</Typography>
          </Grid>
          <Grid item xs={6} className="item-response">
            {!isFornecedor ? (
            <Typography variant="subtitle2" gutterBottom className='margemtexto'>{!containsOnlyNumbersAndSymbols(orcamento?.treinamentos) ? orcamento?.treinamentos : formatCurrency(orcamento?.treinamentos)}</Typography> ) : (
              <TextField
                id="treinamentos"
                name="treinamentos"
                variant="outlined"
                type="number"
                size="small"
                fullWidth
                defaultValue={orcamento?.treinamentos}
                onChange={(e) => orcamento.treinamentos = e.target.value}
              />
            )}
          </Grid>

          <Grid item xs={6} className="item-response">
            <Typography variant="subtitle2" gutterBottom className='margemtexto'>Serviços de consultoria</Typography>
          </Grid>
          <Grid item xs={6} className="item-response">
            {!isFornecedor ? (
            <Typography variant="subtitle2" gutterBottom className='margemtexto'>{!containsOnlyNumbersAndSymbols(orcamento?.servicosConsultoria) ? orcamento?.servicosConsultoria : formatCurrency(orcamento?.servicosConsultoria)}</Typography> ) : (
              <TextField
                id="servicosConsultoria"
                name="servicosConsultoria"
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                defaultValue={orcamento?.servicosConsultoria}
              />
            )}
          </Grid>

          <Grid item xs={6} className="item-response">
            <Typography variant="subtitle2" gutterBottom className='margemtexto'>Serviços (instalação, descarte, obras cívis) </Typography>
          </Grid>
          <Grid item xs={6} className="item-response">
            {!isFornecedor ? (
            <Typography variant="subtitle2" gutterBottom className='margemtexto'>{!containsOnlyNumbersAndSymbols(orcamento?.servicosInstalacao) ? orcamento?.servicosInstalacao : formatCurrency(orcamento?.servicosInstalacao)}</Typography> ) : (
              <TextField
                id="servicosInstalacao"
                name="servicosInstalacao"
                variant="outlined"
                type="number"
                size="small"
                fullWidth
                defaultValue={orcamento?.servicosInstalacao}
                onChange={(e) => orcamento.servicosInstalacao = e.target.value}
              />
            )}
          </Grid>

          <Grid item xs={6} className="item-response-color">
            <Typography variant="subtitle2" gutterBottom className="titleFontColor">Total do Valor </Typography>
          </Grid>
          <Grid item xs={6} className="item-response-color">
            <Typography variant="subtitle2" gutterBottom className="titleFontColor">{formatCurrency(somarItensFinanciaveis())}</Typography>
          </Grid>

        </Grid>

        <Box className="title">
          <Typography align="left" variant="subtitle1" className="titleFontColor">
            DADOS DO PROJETO / CRONOGRAMA FÍSICO
          </Typography>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Meta</TableCell>
                <TableCell>Início</TableCell>
                <TableCell>Fim</TableCell>
                <TableCell>Dias Totais</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {metas.map((meta) => (
                <React.Fragment key={meta.id}>
                  <TableRow>
                    <TableCell>
                      <IconButton onClick={() => handleRowClick(meta.id)}>
                        {openRows[meta.id] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      </IconButton>
                    </TableCell>
                    <TableCell>{meta.medidaEe}</TableCell>
                    <TableCell>{meta.inicio}</TableCell>
                    <TableCell>{meta.fim}</TableCell>
                    <TableCell>{meta.diasTotais}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} style={{ paddingBottom: 0, paddingTop: 0 }}>
                      <Collapse in={openRows[meta.id]} timeout="auto" unmountOnExit>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Atividade</TableCell>
                              <TableCell>Indicador Execução</TableCell>
                              <TableCell>Início</TableCell>
                              <TableCell>Fim</TableCell>
                              <TableCell>Dias</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(atividades[meta.id] || []).map((atividade, index) => (
                              <TableRow key={index}>
                                <TableCell>{atividade.atividades}</TableCell>
                                <TableCell>{atividade.indicadorExecucao}</TableCell>
                                <TableCell>{atividade.inicio}</TableCell>
                                <TableCell>{atividade.fim}</TableCell>
                                <TableCell>{atividade.diasTotais}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      </DialogContent>
      <DialogActions>
        {loading > 0 && (
          <CircularProgress
            size={30}
            sx={{
              position: "absolute",
              left: 25,
              bottom: 12,
            }}
          />
        )}
        {isFornecedor && 
          <React.Fragment>
            <Grid item xs={6}>
              <FormControl sx={{ my: 2 }} fullWidth>
                <FileField
                  field="documentoId"
                  fileData={orcamento?.documentoId}
                  useFormRef={useFormRef}
                  schema={schema}
                />
              </FormControl>
            </Grid>
          </React.Fragment>
        }
        {isFornecedor && <Button onClick={() => handleSave()}>
          SALVAR
        </Button>}
        {isFornecedor && <Button color="secondary" onClick={() => handleSave(true)}>
          ENVIAR
        </Button>}
        <Button color="inherit" onClick={() => close()}>
          FECHAR
        </Button>

      </DialogActions>
    </Dialog>
  );
};

export default DialogoAcompanhaOrcamentosFaseTwo;
